import React from 'react'
import Layout from '../components/layout'
import { Container, Typography } from '@mui/material'
import { useSiteMetadata } from '../hooks/use-site-metadata'

const NotFoundPage = () => {
    const {
        site: {
            siteMetadata: { siteUrl, siteTitle },
        },
    } = useSiteMetadata()

    const title = `404 Not Found | ${siteTitle}`
    const description = `404 Not Found`
    return (
        <Layout title={title} description={description}>
            <Container sx={{ pt: { xs: 3, sm: 6 } }}>
                <Typography variant="h1" sx={{ mb: 2, fontWeight: 'bold' }}>
                    404 Not Found
                </Typography>
            </Container>
        </Layout>
    )
}

export default NotFoundPage
